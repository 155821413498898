'use client';

import { useUsermavenContext } from './context';
import type { Usermaven } from './types';

export const useUsermaven = () => {
  const ctx = useUsermavenContext();

  if (!ctx) {
    throw new Error(
      'useUsermaven() must be called inside <UsermavenProvider />'
    );
  }

  return ctx.client as Usermaven;
};
