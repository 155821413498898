'use client';

import { zodResolver } from '@hookform/resolvers/zod';
import { t } from '@lingui/macro';
import { Button, Stack, ToggleButton, Typography } from '@mui/material';
import { ToggleButtonGroupInput } from '@prismo-io/design-system';
import '@prismo-io/design-system/module-augmentation';
import { type FC, useCallback, useEffect, useMemo } from 'react';
import { type SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';

type Props = {
  question: string;
  pass?: string;
  valid?: string;
  bad?: string;
  good?: string;
  nbItems?: number;
  onComplete: (response: number | null) => void | Promise<void>;
  autoSend?: boolean;
  canUnselect?: boolean;
};

const ValidatorSchema = z.object({
  response: z
    .number({
      coerce: true,
      invalid_type_error: t`Veuillez sélectionner une réponse`,
      required_error: t`Veuillez sélectionner une réponse`,
    })
    .int(t`Veuillez sélectionner une réponse`)
    .min(1, t`Veuillez sélectionner une réponse`),
});

type ValidatorSchemaT = z.infer<typeof ValidatorSchema>;

export const NpsGauge: FC<Props> = (props) => {
  const {
    question,
    onComplete,
    bad = undefined,
    good = undefined,
    pass = undefined,
    valid = t`Valider`,
    nbItems = 10,
    autoSend = false,
    canUnselect = false,
  } = props;

  const items = useMemo(
    () => new Array(nbItems).fill(0).map((_, i) => i + 1),
    [nbItems]
  );

  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid },
  } = useForm<ValidatorSchemaT>({
    resolver: zodResolver(ValidatorSchema),
  });

  const responseWatched = watch('response');

  const onSubmit: SubmitHandler<ValidatorSchemaT> = useCallback(
    (data) => {
      onComplete(data.response);
    },
    [onComplete]
  );

  const onPass = () => {
    onComplete(-1);
  };

  useEffect(() => {
    const subscription = watch(() => {
      if (autoSend) {
        handleSubmit(onSubmit)();
      }
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, onSubmit, watch, autoSend]);

  return (
    <Stack
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      spacing={2}
      padding={1}
    >
      <Typography fontWeight="bold">{question}</Typography>
      <Stack gap={1}>
        {(!!bad || !!good) && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {!!bad && (
              <Typography fontSize="small" fontWeight="light">
                {bad}
              </Typography>
            )}
            {!!good && (
              <Typography fontSize="small" fontWeight="light">
                {good}
              </Typography>
            )}
          </Stack>
        )}
        <ToggleButtonGroupInput
          control={control}
          name="response"
          sx={{ display: 'flex', gap: 1 }}
          size="small"
          color="primary"
          exclusive
        >
          {items.map((value) => (
            <ToggleButton
              key={value}
              value={value}
              disabled={!canUnselect && responseWatched === value}
              sx={{
                flex: 1,
                border: '1px solid gray !important',
                borderRadius: '4px !important',
              }}
            >
              {value}
            </ToggleButton>
          ))}
        </ToggleButtonGroupInput>
      </Stack>
      <Stack
        direction="row"
        justifyContent={!pass ? 'flex-end' : 'space-between'}
        alignItems="center"
      >
        {!!pass && (
          <Button type="button" onClick={onPass} variant="outlined">
            {pass}
          </Button>
        )}
        {!autoSend && (
          <Button type="submit" variant="contained" disabled={!isValid}>
            {valid}
          </Button>
        )}
      </Stack>
    </Stack>
  );
};
