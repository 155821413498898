'use client';

import { env } from '@prismo-io/env';
import { type AppPaths, Application, Link } from '@prismo-io/router';
import {
  type ComponentProps,
  type MouseEventHandler,
  useCallback,
} from 'react';
import { useUsermaven } from './use-usermaven';

type Props<
  App extends Application,
  Path extends AppPaths<App>,
> = ComponentProps<typeof Link<App, Path>> & {
  tracking: {
    method: 'change-origin';
    payload?: Record<string, unknown>;
  };
};

const originMapper: Record<string, Application> = {
  [env.NEXT_PUBLIC_ONBOARDING_URL]: Application.ONBOARDING,
  [env.NEXT_PUBLIC_AURA_URL]: Application.AURA,
  [env.NEXT_PUBLIC_HELIO_URL]: Application.HELIO,
  [env.NEXT_PUBLIC_QUARK_URL]: Application.QUARK,
  [env.NEXT_PUBLIC_APP_URL]: Application.APP,
  [env.NEXT_PUBLIC_TOOLS_URL]: Application.TOOLS,
};

export function LinkTracking<
  App extends Application,
  Path extends AppPaths<App>,
>(props: Props<App, Path>) {
  const { onClick = undefined, tracking, ...rest } = props;

  const usermaven = useUsermaven();

  const onClickAugmented = useCallback<MouseEventHandler<HTMLAnchorElement>>(
    (e) => {
      if (usermaven) {
        if (tracking.method === 'change-origin') {
          const href = e.currentTarget.href;
          const crtOrigin = new URL(window.location.href).origin;
          const newOrigin = new URL(href).origin;

          if (crtOrigin !== newOrigin) {
            const crtApp = originMapper[crtOrigin] || crtOrigin;
            const newApp = originMapper[newOrigin] || newOrigin;

            usermaven.track('link-click', {
              ...(tracking.payload || {}),
              method: tracking.method,
              current_app: crtApp,
              current_url: window.location.href,
              destination_app: newApp,
              destination_url: href,
            });
          }
        }
      }

      if (onClick) {
        onClick(e);
      }
    },
    [onClick, tracking, usermaven]
  );

  return <Link {...(rest as any)} onClick={onClickAugmented} />;
}
