'use client';

import { type AppPaths, type Application, ButtonLink } from '@prismo-io/router';
import { type ComponentProps, useCallback } from 'react';
import { useUsermaven } from './use-usermaven';

type Props<
  App extends Application,
  Path extends AppPaths<App>,
> = ComponentProps<typeof ButtonLink<App, Path>> & {
  tracking: {
    name: string;
    payload?: Record<string, unknown>;
  };
};

export function ButtonLinkTracking<
  App extends Application,
  Path extends AppPaths<App>,
>(props: Props<App, Path>) {
  const { onClick = undefined, tracking, ...rest } = props;

  const usermaven = useUsermaven();

  const onClickAugmented = useCallback(
    (e: any) => {
      if (tracking && usermaven) {
        usermaven.track(tracking.name, tracking.payload ?? undefined);
      }

      if (onClick) {
        onClick(e);
      }
    },
    [onClick, tracking, usermaven]
  );

  return <ButtonLink {...(rest as any)} onClick={onClickAugmented} />;
}
