'use client';

import { type FC, type PropsWithChildren, useCallback } from 'react';
import { useUsermaven } from './use-usermaven';

type Props = {
  eventName: string;
  payload?: Record<string, unknown>;
};

export const ClickTracker: FC<PropsWithChildren<Props>> = (props) => {
  const { children, eventName, payload = undefined } = props;

  const usermaven = useUsermaven();

  const onTrack = useCallback(() => {
    if (usermaven) {
      usermaven.track(eventName, payload);
    }
  }, [usermaven, eventName, payload]);

  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  return <div onClick={onTrack}>{children}</div>;
};
