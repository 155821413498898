'use client';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Collapse, IconButton, Paper } from '@mui/material';
import { type FC, type PropsWithChildren, useState } from 'react';

type Props = {
  isVisible?: boolean;
};

export const RelationshipBanner: FC<PropsWithChildren<Props>> = (props) => {
  const { children, isVisible = false } = props;

  const [open, setOpen] = useState<boolean>(true);

  if (!isVisible) return null;

  return (
    <Box
      component="div"
      position="fixed"
      bottom="1rem"
      right="1rem"
      zIndex={10000}
    >
      <Collapse in={open}>
        <Paper variant="elevation" sx={{ position: 'relative', padding: 1 }}>
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            sx={{
              position: 'absolute',
              top: 1,
              right: 1,
            }}
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
          {children}
        </Paper>
      </Collapse>
    </Box>
  );
};
