'use client';

import { useApi } from '@prismo-io/core';
import {
  HubspotTrackArgs,
  type HubspotTrackArgsT,
  type SWRArgs,
} from '@prismo-io/schemas';
import { useCallback } from 'react';
import useSWRMutation from 'swr/mutation';

export function useHubspotTrack() {
  const { client } = useApi();

  const { trigger } = useSWRMutation(
    'hubspot-track',
    async (key, { arg }: SWRArgs<HubspotTrackArgsT['_properties']>) => {
      const params = HubspotTrackArgs.parse(arg);

      await client.rpc('hubspot_track', params);

      return true;
    }
  );

  const track = useCallback(
    (arg: HubspotTrackArgsT['_properties']) => trigger(arg),
    [trigger]
  );

  return { track };
}
