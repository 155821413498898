'use client';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { env } from '@prismo-io/env';
import { type FC, useEffect } from 'react';

type Props = {
  service: string;
  version?: string;
};

export const DatadogRumListener: FC<Props> = (props) => {
  const { service, version } = props;

  useEffect(() => {
    if (
      !env.NEXT_PUBLIC_DATADOG_APPLICATION_ID ||
      !env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN
    ) {
      return;
    }

    const proxy = new URL('/api/dog', window.location.href).toString();

    // if (!('DD_RUM' in window)) {
    datadogRum.init({
      applicationId: env.NEXT_PUBLIC_DATADOG_APPLICATION_ID,
      clientToken: env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.eu',
      service,
      env: env.NEXT_PUBLIC_APP_ENV,
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 80,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'allow',

      // proxy: '/api/dog'
      proxy: ({ path, parameters }) => `${proxy}${path}?${parameters}`,

      ...(version ? { version } : {}),
    });
    // }

    // if (!('DD_LOGS' in window)) {
    datadogLogs.init({
      clientToken: env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN,
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.eu',
      forwardErrorsToLogs: true,
      sessionSampleRate: 80,

      silentMultipleInit: true,

      // proxy: '/api/dog'
      proxy: ({ path, parameters }) => `${proxy}${path}?${parameters}`,

      ...(version ? { version } : {}),
    });
    // }

    console.info('Type:', service, '=>', version);

    // datadogRum.startSessionReplayRecording();
  }, []);

  return null;
};
