'use client';

import { useLingui } from '@lingui/react';
import { LanguageCode } from '@prismo-io/schemas';
import { useMemo } from 'react';
import { createApiClient } from './create-api-client';

export const useApi = () => {
  const { i18n } = useLingui();

  const locale = useMemo(() => {
    try {
      return LanguageCode.parse(i18n.locale);
    } catch (error) {
      return LanguageCode.Enum.fr;
    }
  }, [i18n.locale]);

  return useMemo(() => {
    const client = createApiClient({ locale });

    return { client };
  }, [locale]);
};
