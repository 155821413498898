'use client';

import { Trans, t } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { CircularProgress, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import {
  LanguageCode,
  type NpsResultsT,
  NpsSurveyContentType,
  type NpsSurveyResponseGaugeT,
  type NpsSurveyResponseTextareaT,
  type NpsSurveysT,
} from '@prismo-io/schemas';
import {
  type FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
  useTransition,
} from 'react';
import type { DeepPartial } from 'react-hook-form';
import { ParamsSchema } from '../../api/upsert-nps-result';
import { useUpsertNpsResult } from '../../hooks/use-upsert-nps-result';
import { NpsGauge } from '../nps-gauge';
import { NpsTextarea } from '../nps-textarea';

type Props = {
  survey: NpsSurveysT;
  onComplete?: (response: boolean) => void | Promise<void>;
};

export const NpsFormStepper: FC<Props> = (props) => {
  const { survey, onComplete = undefined } = props;

  const { i18n } = useLingui();

  const lang = useMemo(() => LanguageCode.parse(i18n.locale), [i18n.locale]);

  const [responses, setResponses] = useState<
    DeepPartial<NpsResultsT['responses']>
  >([]);

  const activeStep = useMemo(() => responses.length, [responses]);

  const step = useMemo(
    () => survey.content[activeStep],
    [survey.content, activeStep]
  );

  const surveySteps = useMemo(() => survey.content.length, [survey.content]);

  const isLoading = useMemo(
    () => activeStep === surveySteps,
    [activeStep, surveySteps]
  );

  const [isTransiting, startTransition] = useTransition();
  const { trigger: upsertNpsResult } = useUpsertNpsResult();

  const onCompleteGauge = useCallback((data: number | null) => {
    const gaugeResponse = {
      kind: NpsSurveyContentType.Enum.GAUGE,
      response: data,
    } satisfies NpsSurveyResponseGaugeT;
    setResponses((prev) => [...prev, gaugeResponse]);
  }, []);

  const onCompleteTextarea = useCallback((data?: string | null) => {
    const textareaResponse = {
      kind: NpsSurveyContentType.Enum.TEXTAREA,
      response: data ? data : '',
    } satisfies NpsSurveyResponseTextareaT;
    setResponses((prev) => [...prev, textareaResponse]);
  }, []);

  useEffect(() => {
    if (isLoading) {
      const validValues = ParamsSchema.parse({
        nps_survey_id: survey.id,
        responses: responses,
      });
      upsertNpsResult(validValues, {
        onSuccess: async () => {
          startTransition(() => {
            if (onComplete) onComplete(true);
          });
        },
      });
    }
  }, [isLoading, responses]);

  if (isLoading) {
    return (
      <Stack
        width="100%"
        minWidth="300px"
        minHeight="100px"
        direction="row"
        justifyContent="center"
        component="article"
      >
        <Stack direction="column" justifyContent="center" alignItems="center">
          <CircularProgress />
          <Typography>
            <Trans>Envoie de vos réponses...</Trans>
          </Typography>
        </Stack>
      </Stack>
    );
  }

  if (!step) {
    return null;
  }

  return (
    <Stack
      width="100%"
      minWidth="300px"
      direction="row"
      justifyContent="center"
      component="article"
    >
      <Box component="div" width="100%" maxWidth="20rem">
        {/* <Typography variant="h2">{survey.name}</Typography> */}
        {step.kind === NpsSurveyContentType.Enum.GAUGE && (
          <NpsGauge
            question={step.sentence[lang]}
            bad={!!step.bad ? step.bad[lang] ?? undefined : undefined}
            good={!!step.good ? step.good[lang] ?? undefined : undefined}
            pass={activeStep !== 0 ? t`Passer` : undefined}
            valid={activeStep < surveySteps - 1 ? t`Suivant` : t`Valider`}
            onComplete={onCompleteGauge}
          />
        )}
        {step.kind === NpsSurveyContentType.Enum.TEXTAREA && (
          <NpsTextarea
            question={step.sentence[lang]}
            placeholder={
              !!step.placeholder
                ? step.placeholder[lang] ?? undefined
                : undefined
            }
            pass={activeStep !== 0 ? t`Passer` : undefined}
            valid={activeStep < surveySteps - 1 ? t`Suivant` : t`Valider`}
            onComplete={onCompleteTextarea}
          />
        )}
      </Box>
    </Stack>
  );
};
